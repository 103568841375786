import React, { useState, useEffect, useRef } from "react";
import {
  Paperclip,
  Mic,
  Send,
  ThumbsUp,
  ThumbsDown,
  Square,
  Loader,
  HeartHandshake,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import homeownerImage from "./assets/images/homeowner.png"; // Ensure this path is correct

const API_URL = "https://api.dify.ai/v1";
const API_KEY = "app-YreFHtOU7a5gztPnX7qW7Vex";

const MunichLegalAssistant = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [streamingMessage, setStreamingMessage] = useState("");
  const [currentTaskId, setCurrentTaskId] = useState(null);
  const abortControllerRef = useRef(null);

  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [input]);

  
  const addBGBLinks = (text) => {
    const bgbPattern =
      /§\s*(\d+[a-z]?)(?:\s*(Abs\.\s*\d+))?(?:\s*(Satz\s*\d+))?\s*BGB/g;
    return text.replace(bgbPattern, (match, section, absatz, satz) => {
      const cleanedSection = section.trim();
      let url = `https://www.gesetze-im-internet.de/bgb/__${cleanedSection}.html`;

      // If there's an Absatz, add it to the URL
      if (absatz) {
        const absatzNumber = absatz.match(/\d+/)[0];
        url += `#absatz-${absatzNumber}`;
      }

      return `[${match}](${url})`;
    });
  };


  const sendMessage = async () => {
    console.log("Sending message:", input);
    if (!input.trim() || isLoading) return;

    setIsLoading(true);
    const newMessage = { role: "user", content: input };
    setMessages([...messages, newMessage]);
    setInput("");
    setStreamingMessage("");

    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    try {
      const response = await fetch(`${API_URL}/chat-messages`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${API_KEY}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputs: {},
          query: input,
          response_mode: "streaming",
          conversation_id: conversation?.id || "",
          user: "abc-123",
          files: [],
        }),
        signal,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`API error: ${errorData.message}`);
      }

      const reader = response.body.getReader();
      let partialResponse = "";
      let fullResponse = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        partialResponse += new TextDecoder().decode(value);
        const chunks = partialResponse.split("\n\n");

        console.log("Chunks:", chunks);

        for (let i = 0; i < chunks.length - 1; i++) {
          const chunk = chunks[i];
          if (chunk.startsWith("data:")) {
            try {
              const data = JSON.parse(chunk.slice(5));
              if (data.event === "message") {
                fullResponse += data.answer || "";
                setStreamingMessage(fullResponse);
                setCurrentTaskId(data.task_id);
              } else if (data.event === "message_end") {
                const processedResponse = addBGBLinks(fullResponse);
                setMessages((prevMessages) => [
                  ...prevMessages,
                  {
                    id: data.id,
                    role: "assistant",
                    content: processedResponse,
                    price: (data.metadata?.usage?.total_price || 0) * 1,
                    currency: data.metadata?.usage?.currency,
                  },
                ]);
                setStreamingMessage("");
                setCurrentTaskId(null);
                if (!conversation && data.conversation_id) {
                  setConversation({ id: data.conversation_id });
                }
              }
            } catch (error) {
              console.error("Error parsing chunk:", error);
            }
          }
        }
        partialResponse = chunks[chunks.length - 1];
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Error sending message:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: `Error: ${error.message}` },
        ]);
      }
    } finally {
      setIsLoading(false);
      setCurrentTaskId(null);
      console.log("Loading finished");
    }
  };

  const stopGeneration = async () => {
    if (currentTaskId) {
      try {
        const response = await fetch(
          `${API_URL}/chat-messages/${currentTaskId}/stop`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${API_KEY}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ user: "abc-123" }),
          }
        );
        if (!response.ok) {
          throw new Error("Failed to stop generation");
        }
        abortControllerRef.current.abort();
      } catch (error) {
        console.error("Error stopping generation:", error);
      }
    }
  };

  const handleFeedback = async (messageId, feedback) => {
    try {
      const response = await fetch(
        `${API_URL}/messages/${messageId}/feedbacks`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${API_KEY}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            rating: feedback,
            user: "abc-123",
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to submit feedback");
      }
      // Update the UI to reflect the feedback
      setMessages(
        messages.map((msg) =>
          msg.id === messageId ? { ...msg, feedback } : msg
        )
      );
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const formatPrice = (price, currency) => {
    const numPrice = parseFloat(price);
    if (currency === "USD") {
      return `$${numPrice.toFixed(2)}`;
    }
    return `${numPrice.toFixed(2)} ${currency}`;
  };

  return (
    <div className="flex flex-col h-screen bg-[#f9f5ed] text-gray-800">
      <header className="bg-[#f9f5ed] p-4 border-b border-gray-200">
        <div className="max-w-3xl mx-auto flex justify-between items-center">
          <div className="flex items-center">
            <img
              src={homeownerImage}
              alt="Homeowner"
              className="w-10 h-10 mr-2 object-cover rounded-full"
            />
            <h1 className="text-xl font-semibold flex items-center">
              MingaHub v0.1
            </h1>
          </div>
          <div className="text-sm bg-gray-100 px-2 py-1 rounded">
            Professional Plan
          </div>
        </div>
      </header>

      <main className="flex-grow overflow-auto p-4">
        <div className="max-w-3xl mx-auto space-y-4">
          <h2 className="flex items-center text-3xl font-light">
            <HeartHandshake
              size={30}
              color="#ff0000"
              strokeWidth={2}
              style={{ marginRight: "10px" }}
            />
            Guten Morgen, Rudi!
          </h2>

          {messages.map((message, index) => (
            <div
              key={index}
              className={`bg-white rounded-lg p-4 shadow-sm ${
                message.role === "user"
                  ? "ml-auto align-right"
                  : "mr-auto align-left"
              } custom-width`}
            >
              <div
                className={`flex items-center mb-2 ${
                  message.role === "user" ? "flex-row-reverse" : ""
                }`}
              >
                <div
                  className={`w-6 h-6 rounded-full ${
                    message.role === "user" ? "bg-green-500" : "bg-blue-500"
                  } text-white flex items-center justify-center text-xs mr-2`}
                >
                  {message.role === "user" ? "D" : "A"}
                </div>
                <div className="text-sm font-semibold">
                  {message.role === "user" ? "Du" : "MingaHub"}
                </div>
              </div>
              <div className="text-sm prose max-w-none">
                <ReactMarkdown
                  components={{
                    a: ({ node, ...props }) => (
                      <a {...props} target="_blank" rel="noopener noreferrer" />
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
              </div>
              {message.role === "assistant" && (
                <div className="mt-2 flex items-center justify-between">
                  <div className="text-xs text-gray-500">
                    {message.price &&
                      `Gesamt Kosten: ${formatPrice(
                        message.price,
                        message.currency
                      )}`}
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleFeedback(message.id, "like")}
                      className={`like-button ${
                        message.feedback === "like" ? "active" : ""
                      }`}
                    >
                      <ThumbsUp size={16} />
                    </button>
                    <button
                      onClick={() => handleFeedback(message.id, "dislike")}
                      className={`dislike-button ${
                        message.feedback === "dislike" ? "active" : ""
                      }`}
                    >
                      <ThumbsDown size={16} />
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}

          {streamingMessage && (
            <div className="bg-yellow-50 rounded-lg p-4 shadow-sm mr-auto align-left custom-width">
              <div className="flex items-center mb-2">
                <div className="w-6 h-6 rounded-full bg-blue-500 text-white flex items-center justify-center text-xs mr-2">
                  A
                </div>
                <div className="text-sm font-semibold">MingaHub</div>
              </div>
              <div className="text-sm prose max-w-none">
                <ReactMarkdown>{streamingMessage}</ReactMarkdown>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          )}
        </div>
      </main>

      <footer className="bg-[#f9f5ed]4 p-4">
        <div className=" max-w-3xl mx-auto">
          <div className="flex bg-white rounded-lg shadow-sm align-start">
            <textarea
              ref={textareaRef} // Referans ekle
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Wie kann ich dir heute helfen?"
              className="w-full p-3 rounded-lg resize-none focus:outline-none"
            />
            <span className="flex justify-between items-center p-2">
              {isLoading ? (
                <button
                  onClick={stopGeneration}
                  className="bg-red-500 text-white  w-10 h-10 flex items-center justify-center rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                >
                  <Loader size={16} />
                </button>
              ) : (
                <button
                  onClick={sendMessage}
                  disabled={isLoading}
                  className="bg-blue-500 text-white w-10 h-10 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center justify-center"
                >
                  <Send size={16} />
                </button>
              )}
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default MunichLegalAssistant;
