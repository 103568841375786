import React, { useEffect } from "react";
import MingaHubAssistant from "./MingaHubAssistant";

// Import the favicon
import iconPath from "./assets/images/homeowner.png"; // Ensure this path is correct and accessible

function App() {
  useEffect(() => {
    // Set the page title
    document.title = "MingaHub Assistant";

    // Create a link element for the favicon
    const link = document.createElement("link");
    link.rel = "icon";
    link.href = iconPath;
    link.type = "image/x-icon";

    // Append the link element to the head of the document
    document.head.appendChild(link);

    // Return a cleanup function to remove the link when the component unmounts
    return () => {
      document.head.removeChild(link);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render

  return (
    <div className="App">
      <MingaHubAssistant />
    </div>
  );
}

export default App;
